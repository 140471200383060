<template>
  <div class="header">
      <div class="menu">
        <div class="menu_action">
          <i :class="`${isCollapse? 'el-icon-s-unfold':'el-icon-s-fold'} cursor-pointer`" @click="handleCollapse"></i>
        </div>
      </div>
      <div>
          <el-dropdown class="user_Info" @visible-change="val=>userMenuVisible=val" size="default">
            <div class="cursor-pointer">
              <div>
                <span class="userInfo_name">你好，{{$store.getters.currentUserName}} </span>
              </div>
            <!-- <i :class="[userMenuVisible?'el-icon-arrow-up':'el-icon-arrow-down', 'el-icon--right']"></i> -->
            <i :class="[userMenuVisible?'el-icon-arrow-up':'el-icon-arrow-down', 'el-icon--right']"></i>
            </div>
            <el-dropdown-menu slot="dropdown" class="user_info_drop_menu">
              <el-dropdown-item @click.native="goToPage(1)" com><i class="el-icon-document"/>个人资料</el-dropdown-item>
              <el-dropdown-item @click.native="goToPage(2)"><i class="el-icon-office-building"/>我的企业</el-dropdown-item>
              <el-dropdown-item @click.native="handleLoginOut"><i class="el-icon-switch-button"/>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </div>
      <div class='companyContainer'>
        <el-dropdown  class="user_Info">
          <span class="el-dropdown-link cursor-pointer">
            {{$store.getters.currentCompanyName}}<i class="icon-genghuan iconfont el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command='c.supplierId' :disabled='c.deleteFlag' :class="{active:$store.state.User.currentSupplierId===c.supplierId}" @click.native="switchCompany(c.supplierId)" v-for="c in $store.state.User.allCompanyLists" :key="c.supplierId">{{c.supplierName}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
  </div>
</template>

<script>
import {logout, switchSupplier} from '@/api/userCenter';
export default {
  name: 'Header',
  data: function () {
    return {
      userMenuVisible: false,
      isCollapse: false,
    };
  },
  methods: {
    handleLoginOut () {
      // 退出操作
      this.$confirm('确认是否要退出？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 确认操作;
        logout().then(() => {
          this.$local.clear();
          this.$session.clear();
          this.$router.push({
            name: 'Login',
          });
        });
      });
    },
    handleCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$emit('collapse', this.isCollapse);
    },
    goToPage (status) {
      let name = status === 1 ? 'PersonalCenter' : 'Company';
      this.$router.push({name});
    },
    switchCompany (supplierId) {
      switchSupplier({supplierId: supplierId}).then((res) =>{
        this.$store.dispatch('changeCurrentSupplierId', supplierId);
        this.$store.dispatch('changeUserInfo', res.body);
        this.$router.push({
          name: 'HOME',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
    .header {
        height: 47px;
        display: flex;
        background: white;
        color: #242832;;
        align-items: center;
        padding: 0 30px;
        font-size: 14px;
        border-bottom: 1px solid rgba(113,121,141,0.08);
        box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.08);

        .menu{
            flex: 1;

            .el-menu {
              border:none;

              .el-menu-item {
                height: 46px;
                line-height: 46px;
              }
            }
        }
        .logo {
            width: 280px;
            font-size: 16px;
            font-size: 0px;
            vertical-align: middle;
            color: #242832;
        }

        .user_Info{
          color: #242832;
          height: 100%;
          display: flex;
          align-items: center;
          &>div{
              display: flex;
              align-items: center;

              &>div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 8px;
                .userInfo_warehouse{
                  font-size: 9px;
                  color: gray;
                }
              }
            }
        }
    }

    .user_info_drop_menu {
        min-width: 120px
    }
    .infoMessage{
      font-size: 12px;
      text-align: right;
      transform: scale(0.9);
    }
    .el-dropdown-menu--small .el-dropdown-menu__item.active{
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  .companyContainer{
    margin-left:50px;
    margin-right:10px;
  }
  .iconfont{
    font-size:14px;
    margin-left:5px;
  }
</style>
